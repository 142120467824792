(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("firebase"));
	else if(typeof define === 'function' && define.amd)
		define("flamelink", ["firebase"], factory);
	else if(typeof exports === 'object')
		exports["flamelink"] = factory(require("firebase"));
	else
		root["flamelink"] = factory(root["firebase"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__102__) {
return 